import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import * as React from "react";
import { useContext } from "react";
import { ReactSVG } from "react-svg";
import KlaviyoSubscriber from "src/components/organisms/KlaviyoSubscriber";

import { CustomLink } from "@components/atoms";
import { SocialMediaIcon } from "@components/legacy";
import { MenuContext } from "@components/legacy/MenuProvider/context";

import { SOCIAL_MEDIA } from "../../../core/config";

import logoImg from "images/the_volte_logo_black.svg";

export const Footer: React.FC = () => {
  const { footer } = useContext(MenuContext);

  return (
    <Box
      bgColor="tertiary"
      color="white"
      padding={["4rem 1rem 1rem 1rem", null, null, "4rem 3rem"]}
    >
      <Flex gap={5} direction={["column", "column", null, "row"]}>
        <Grid
          w="full"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap={6}
        >
          {footer?.items?.map((item) => {
            if (!item) {
              return null;
            }
            return (
              <GridItem key={item.name} w="100%" pb="1rem">
                <Box fontWeight="600" mb="1rem">
                  {item.name}
                </Box>
                <Box>
                  {item?.children?.map((subItem) => {
                    if (!subItem) {
                      return null;
                    }

                    return (
                      <Box
                        key={subItem.name}
                        my="0.3rem"
                        fontSize={["0.8rem", null, null, "1rem"]}
                      >
                        <CustomLink
                          path={subItem.url || "/"}
                          // rel={subItem?.rel}
                          // target={subItem?.target}
                        >
                          {subItem.name}
                        </CustomLink>
                      </Box>
                    );
                  })}
                </Box>
              </GridItem>
            );
          })}
        </Grid>
        <Box>
          <KlaviyoSubscriber />
          <Box
            display="flex"
            alignItems={[null, null, null, "center"]}
            justifyContent="start"
            flexDirection={["column", null, null, "row"]}
          >
            <Box mr="1rem">FOLLOW&nbsp;US</Box>
            <Box display="flex" mt={["1rem", null, null, "0"]}>
              {SOCIAL_MEDIA.map((medium) => (
                <Box key={medium.ariaLabel} mr="1rem">
                  <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            mt="3rem"
            width={["60%", "50%", "50%", "80%", "90%", "100%"]}
            marginLeft={["-10px", "-10px", "-10px", "-15px", "-15px", "-15px"]}
          >
            <ReactSVG src={logoImg.src} />
          </Box>
          <Box mt="1rem">
            {`The Volte ${new Date().getFullYear()}. All rights reserved.`}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
